html, body {
    font-family: $font-stack;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
}

html {
    position: relative;
    min-height: 100%;
    overflow-y: visible;
    overflow-x: hidden;
}

body {
    margin-bottom: 15px; // height of the footer
    overflow-x: hidden;
    width: 100%;
}

.clear {
    display: block;
    clear: both;
}

.col-clear {
    width: 100% !important;
}

table th {
    text-align: left;
}

.alignleft {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

.alignright {
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
}

.aligncenter {
    display: block;
    margin: 0 auto;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
	text-align: right;  
}

.text-md-right {
	@include respond-to(from-md) {
		text-align: right;
	}    
}

img {
    height: auto;
    max-width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img[src$=".svg"] {
        height: auto;
        width: 100%;
    }
}

a.post-thumbnail-link {
    display: block;
    position: relative;
    overflow: hidden;

    img {
        @include transition(all .5s ease);
        vertical-align: middle;
    }

    &:hover {
        img {
            @include transform(scale(1.2));
        }
    }

}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; // 16:9
    padding-top: 25px;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
}

.admin-bar #top {
    top: 32px !important;
}

@media screen and (max-width: 782px) {
    .admin-bar #top {
        top: 46px !important;
    }
}