html.off-canvas,
html.off-canvas body {
//     height: 100%;
    overflow: hidden !important;
    position: relative;
}

.menu-button {
    color: #FFF;
    display: block;
    font-size: 18px;
    text-align: right;
    text-transform: uppercase;
    padding: 10px 15px;
    width: 100%;

    @include respond-to(from-lg) {
        display: none;
    }
    
    span.button-text {
        @include respond-to(only-xs) {
            display: none;
        }
    }

    #nav-toggle {
        cursor: pointer;
        display: inline-block;
        height: 20px;
        margin-top: -2px;
        margin-left: 15px;
        padding: 8px 0 8px 0;
        position: relative;
        vertical-align: middle;
        width: 25px;

        span,
        span:before,
        span:after {
            background: #FFF;
            content: '';
            display: block;
            height: 4px;
            position: absolute;
            width: 100%;
            @include transition(all 300ms ease-in-out);
        }

        span:before {
            top: -8px;
        }

        span:after {
            top: 8px;
        }
    }

    &.active {
        #nav-toggle {
            span {
                background-color: transparent;
            }

            span:before,
            span:after {
                top: 0;
            }

            span:before {
                @include transform(rotate(45deg));
            }

            span:after {
                @include transform(rotate(-45deg));
            }
        }
    }
}

nav {
    display: block;
    position: relative;
    padding: 0;
    width: 100%;
    
    .menu {
        display: block;
        
        ul {
            display: block;
            margin: 0;
            padding: 0;
            
            li {
                list-style-type: none;
                position: relative;
                
                &.nav-user {
                    background-color: color(background-light);
                    border-radius: 36px;
                    margin: -10px -30px -10px 10px;
                    padding: 10px 30px 10px 10px;
                    position: relative;

                    @include respond-to(only-lg) {
                        padding: 10px 0;
                    }

                    
                    
                    a {
                        
                        .user-photo {
                            display: inline-block;
                            margin: -5.5px 10px -5.5px 0;
                            vertical-align: middle;
                            
                            img {
                                border: 3px solid #FFF;
                                display: block;
                                margin: 0;
                                max-width: 40px;
                                vertical-align: middle;
                                @include border-radius(50%);
                            }
                            
                            svg {
                                display: none;
                            }
                        }
                        
                        .user-name {
                            display: inline-block;
                            margin-left: 5px;
                            min-width: 36px;
                            text-align: left;
                            position: relative;
                            vertical-align: middle;

                            
                            span.name {
                                color: #00787e;
                                display: block;
                                font-size: 16px;
                                font-weight: 800;
                            }
                            
                            span.role {
                                color: color(text);
                                display: block;
                                font-size: 12px;
                                font-style: italic;
                                font-weight: 200;
                                text-transform: capitalize;
                            }
                        }
                        
                        
                        &:hover {
                            
                            .user-photo {
                                img {
                                    border-color: color(primary);
                                }
                            }
                            
                            .user-name {
                                span.name {
                                    color: color(primary);
                                }
                            }
                        }
                        
                        
                    }
                    
                    ul {
                        display: none;
                        
                        li {
                            display: block;
                            text-align: left;
                            
                            a, 
                            button {
                                background-color: transparent;
                                border: 0;
                                border-radius: 0;
                                color: color(text);
                                font-family: $font-stack;
                                font-size: 16px;
                                font-weight: 800;
                                min-width: 0;
                                padding: 5px 0;
                                text-decoration: underline;
                                text-transform: none;
                                -webkit-appearance: none;
                                text-align: left;
                                white-space: nowrap;
                                
                                &:hover {
                                    color: color(secondary);
                                }
                            }
                            
                        }
                    }

                }
            }
        }
    }
    
    &.header-nav {
        
        @include respond-to(up-to-md) {
            display: none;
        }
        
        .menu {
            
            ul {
                text-align: right;
                
                .nav-item-container {
                    display: inline-block;
                }
                
                li {
                    display: inline-block;
                    
                    a {
                        color: #FFF;
                        display: inline-block;
                        font-size: 16px;
                        font-weight: 400;
                        padding: 10px;
                        text-decoration: none;
                        text-transform: uppercase;
        
                        &:hover {
                            color: color(tertiary);
                        }
                    }
                    
                    &.active {
                        
                        a {
                            color: color(tertiary);
                            font-weight: 700;
                        }
                    }
                    
                    .touch-button {
                        display: none;
                    }
                    
                    &.nav-user {

                        ul {
                            left: auto;
                            right: 0;
                            border-radius: 36px;

                            li {
                                border-radius: 50%;

                                button {
                                    background: none !important;
                                }
                            }
                        }
                        
                        &:hover {
                            a {
                                .user-photo {
                                    position: relative;
                                    
                                    img {
                                        border-color: color(primary);
                                        
                                    }
                                    

                                }
                                
                                .user-name {
                                    span.name {
                                        color: color(primary);
                                    }
                                }
                            }
                        }
                    }
                    
                    ul {
                        background-color: color(background-light);
                        min-width: 100%;                        
                        padding: 20px 25px;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        
                        li {
                            
                            &.active {
                                a {
                                    color: color(secondary);
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
    
    &.off-canvas-nav {
        
        @include respond-to(from-lg) {
            display: none;
        }
        
        .menu {
            -ms-flex-align: center;
            align-items: center;
            background-color: color(secondary);
            border-top: 10px solid #FFF;
            display: none;
            position: fixed;
            overflow-y: scroll;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 350;
            
            ul {
                height: 100%;
                padding: 30px;
                width: 100%;
                
                li {
                    display: block;
                    text-align: center;
                    width: 100%;

                    a {
                        color: #FFF;
                        display: block;
                        font-size: 30px;
                        padding: 15px;
                        text-decoration: none;
                        
                    }
                    
                    .touch-button {
                        position: absolute;
                        top: 32px;
                        right: 30px;
                    }
                    
                    &.nav-user {
                        margin: 15px 0;
                        text-align: left;

                        a {
                            padding: 0 0 0 5px;
                            
                            .user-photo {
                                margin: 0px 10px 0 0;
                            }
                        }

                        .touch-button {
                            top: 22px;
                        }
                    }

                    .search-form-container {
                        margin-top: 0 !important;
                    }
                    
                    ul {
                        li {
                            text-align: center;
                            margin-bottom: 15px;
                            
                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        
        // @include respond-to(up-to-md) {}
    }
}