//for IE11+
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	html, body {
		height: 100%;
	}
	.app {
	    min-height: 100%;
	    height: auto !important;
	    height: 100%;	
	}	
}

body {
    padding-top: 87px; // Header + 20
    margin: 0;

    &.has-search-form {
        padding-top: 147px; // Original padding + 58px
    }
}

main {
	display: block; //for IE
    min-height: 100%;
    margin-bottom: 143px;
    position: relative;
    
    @include respond-to(from-lg){
        margin-bottom: 79px; // Footer
         
        &.footer-removed {
            margin-bottom: 0;
        }
    }
    
}

img.richtext-image {
    
    &.full-width {
        width: 100%;
    }
    
    &.left {
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    
    &.right {
        float: right;
        margin-left: 20px;
        margin-bottom: 20px;
    }
}

main,
.min-height-header-footer {
    min-height: calc( 100vh - ( 62px + 20px + 143px ) ); // Header + 20 + Footer + PageNav (mobile)
    
    @include respond-to(from-lg){
        min-height: calc( 100vh - ( 67px + 20px + 99px ) ); // Header + 20 + Footer
    }
    
    @include respond-to(from-xl){
        min-height: calc( 100vh - ( 67px + 20px + 79px ) ); // Header + 20 + Footer
    }
    
    // Other min-heights are set in draggableMap.scss and page-nav.scss
}

.min-height-header,
.pnlm-container {
    min-height: calc( 100vh - ( 62px + 20px ) ); // Header + 20 + Footer + PageNav (mobile)
    
    @include respond-to(from-lg){
        min-height: calc( 100vh - ( 67px + 20px ) ); // Header + 20 + Footer
    }
    
    @include respond-to(from-xl){
        min-height: calc( 100vh - ( 67px + 20px ) ); // Header + 20 + Footer
    }
    
}

header#top {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7dad4f+50,4e8fc6+50 */
    background-color: #00787e;
    padding: 10px 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 250; // higher z-index than nav.off-canvas-nav .menu
    
    @include respond-to(from-md){
        position: fixed;
    }

    .search-form-container {
        background-color: color(background-light);
        margin-top: -10px;
        margin-bottom: 10px;
        padding: 10px 0;

        form {
            background: none;
            background-color: #FFF;
            position: relative;
            padding: 0;

            @include respond-to(from-lg) {
                display: flex;
                margin-right: 20px;
            }

            #s {
                background: #FFF none;

                @include respond-to(from-md) {
                    display: inline-block;
                    flex: 1;
                }
            }

            @include respond-to(up-to-md) {
                .custom-select {
                    width: 100% !important;
                }
            }

            button[type="submit"] {
                background: none;

                @include respond-to(from-md) {
                    min-width: 0;
                    width: auto;
                }

                svg {
                    color: color(secondary);
                    cursor: pointer;
                    position: relative;
                    top: auto;
                    right: auto;

                    &:hover {
                        color: color(primary);
                    }
                }
            }
        }

        .close-search-form {
            color: color(warning);
            cursor: pointer;
            position: absolute;
            top: 50%;
            right: 15px;

            @include transform(translateY(-50%));

            &:hover {
                color: #000;
            }
        }

        .custom-select {

            .select-selected {
                background-color: color(secondary);
                color: #FFF;
                padding: 10px;

                &.select-arrow-active::after {
                    top: 12px !important;
                }

                &::after {
                    top: 18px;
                }
            }

            .select-items {
                background-color: color(secondary);
            }
        }
    }
    
    .container {
        position: relative;
        
        .header-inner-container {
            display: -ms-flexbox;
            display: flex;
            
            &:before {
                background-color: color(secondary);
                content: '';
                position: absolute;
                top: -10px;
                right: 5px;
                bottom: -10px;
                width: 100vw;
                -webkit-border-top-right-radius: 33px;
                -webkit-border-bottom-right-radius: 33px;
                -moz-border-radius-topright: 33px;
                -moz-border-radius-bottomright: 33px;
                border-top-right-radius: 33px;
                border-bottom-right-radius: 33px;

                @include respond-to(from-md) {
                    right: -15px;
                }

            }


            > .row {
                position: relative;
            }
            
            .main-title-container {
                -ms-flex-align: center;
                align-items: center;
                display: -ms-flexbox;
                display: flex;
                flex: 1;
                
                .main-title {
                    color: #FFF;
                    font-family: $font-stack;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 20px;
                    position: relative;
                    text-transform: uppercase;
                    text-decoration: none;

                    a {
                        color: white;
                        text-decoration: none;
                    }

                    span {
                        display: block;
                        font-size: 13px;
                        line-height: 20px;

                    }


                }
            }
            
            .logo-container {
                -ms-flex-align: center;
                align-items: center;
                display: -ms-flexbox;
                display: flex;
                justify-content: center;
                margin-top: -23px;
                margin-bottom: -15px;
                padding-top: 10px;
                padding-bottom: 6px;
                position: relative;
                text-align: center;

                @include respond-to(up-to-sm){
                    margin-right: 15px;
                }

                .logo {
                    max-width: 75px;
                    
                    @include respond-to(from-md){
                        max-width: 130px;
                    }
                }
            }


        }
        
        
    }

}

/*
 * Readspeaker
*/

.rsbtn {
    display: inline-block;
    margin: 30px 0 0 0;
    
    @include respond-to(from-md){
        margin: 0;
        position: absolute !important;
        top: 20px;
        right: 15px;    
    }
    
    &.rsexpanded {
        width: auto;
        white-space: nowrap;
        
        .rsbtn_exp_inner {
            min-width: 260px;
        }
        
        .rsbtn_exp {
            display: inline-block !important;       
        }
    }
    
    .rsbtn_tooltoggle {
        display: none !important;
    }
    
    button {
        min-width: 0;
    }
}

.main-container {
    padding-top: 30px;
    padding-bottom: 30px;

    @include respond-to(up-to-sm) {
        padding-top: 0;
    }
    
    .main-inner {
        flex-direction: column;
    }
}

.responsive-object {
    position: relative;
}
.responsive-object iframe,
.responsive-object object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.page-body {
    
    .responsive-object {
        margin: 50px 0;
    }
    
    &:last-child {
        margin-bottom: 0 !important;
    }
}

.block-rich_text {
    &::after {
        content: '';
        clear: both;
        display: block;;
    }
}

#readspeaker, .readspeaker {
    width: 100%;

    h1{
        font-weight: 100;
    }

    .block-rich_text {
        p {
            font-size: 16px;
        }
    }
}



.pnlm-container {
    height: auto !important;
}

.button-container {
    text-align: center;
    width: 100%;

    @include respond-to(from-md){
        text-align: right;
    }
}

.videowrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	
	iframe {
    	position: absolute;
    	top: 0;
    	left: 0;
    	width: 100%;
    	height: 100%;

    }
    
}

.mt {
    margin-top: 30px;
}
.mb {
    margin-bottom: 30px;
}
    

.sidebar {
    padding-top: 30px;
    
    @include respond-to(from-md){
        padding-top: 75px;
    }
    
    &.sidebar-bottom {
        padding-top: 0;
        padding-bottom: 30px;
        
    }
    
    .button-container {

        &:first-child {
            margin-bottom: 30px;
        }
        &:not(:first-child) {
            margin-top: auto;
        }
    }

    .side-menu {
        background-color: color(overlay_correct_light);
        margin-bottom: 50px;
        padding: 30px;
        position: relative;
        width: 100%;

        @include respond-to(from-lg){
            padding: 50px;
        }

        &::after {
            border-style: solid;
            border-width: 34px 44px 0 0;
            border-color: color(overlay_correct_light) transparent transparent transparent;
            content: '';
            height: 0;
            position: absolute;
            top: 100%;
            right: 30px;
            width: 0;

            @include respond-to(from-lg){
                right: 50px;
            }
        }

        h2 {
            color: color(secondary);
            font-size: 22px;
            margin-top: 0;
            padding: 0;
            text-transform: uppercase;
        }

        .side-menu-block {
            border-bottom: 1px solid color(border-light);
            margin-bottom: 30px;
            padding-bottom: 30px;

            &:last-of-type {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            ol {
                margin: 0;
                padding: 0 0 0 15px;

                li {

                    a {
                        color: color(text);
                        font-weight: 400;
                        text-decoration: underline;

                        @include respond-to(from-lg) {
                            &:hover {
                                color: color(secondary);
                            }
                        }
                    }
                }

                .hidden-links {
                    display: none;
                }

                .show-hidden-links {
                    color: color(text);
                    cursor: pointer;
                    font-weight: 800;
                    margin-top: 10px;
                    margin-left: -15px;

                    @include respond-to(from-lg) {
                        &:hover {
                            color: color(secondary);
                        }
                    }

                    span {
                        pointer-events: none;
                        text-decoration: underline;
                    }

                    svg {
                        margin-left: 10px;
                        pointer-events: none;
                    }
                }
            }

            &:nth-of-type(2n+0) {
                ol {
                    list-style-type: upper-alpha;
                }
            }
        }

    }
}

ul.breadcrumbs {
    margin: 0;
    padding: 0;

    li {
        display: inline-block;

        a, span {
            color: color(text);
            font-size: 14px;
            font-style: italic;
            font-weight: 300;
            text-decoration: underline;
        }

        span {
            text-decoration: none;
        }
        
        @include respond-to(from-lg) {

            a:hover {
                color: color(secondary);
            }
        }

        span.arrow {
            margin: 0 5px;
        }
        
        &:last-of-type {

            span.arrow {
                display: none;
            }
        }
    }
}

table.overview {
    border-collapse: collapse;
    color: color(text);
    width: 100%;

    thead,
    tbody {
        tr {
            border-bottom: 2px solid #FFF;

            td {
                border-left: 2px solid #FFF;

                &:first-child {
                    border: 0;
                }
            }
        }
    }

    thead {
        @include respond-to(up-to-sm){
            display: none;
        }

        tr {
            th {
                padding: 10px 20px;
            }
        }
    }

    tbody {
        tr {
            td {
                background: color(background-light);
                font-size: 14px;
                height: 60px;
                padding: 10px 20px;
                position: relative;

                @include respond-to(up-to-sm){
                    display: inline-block;
                    height: auto;
                    width: 100%;

                    &::before {
                        content: attr(data-label);
                        color: color(text);
                        display: block;
                        font-weight: 800;
                        margin-right: 15px;
                    }
                }


                span {
                    display: block;
                }

                &.t-list {

                    span {
                        display: inline-block;
                    }

                    span:not(:last-of-type)::after {
                        content: ',';
                        margin-right: 2.5px;
                    }
                }
            }
        }
    }


    &.td-links {

        tbody {
            tr {
                cursor: pointer;

                td.name span:first-of-type {
                    color: color(secondary);
                    text-decoration: underline;

                    svg {
                        font-size: 14px;
                        margin-left: 10px;
                        position: relative;
                        left: 0;
                        @include transition(all .3s ease);
                    }
                }

                @include respond-to(from-lg) {
                    &:hover {
                        outline: 1px solid color(secondary);

                        td.name span {
                            svg {
                                left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* DIV: Table*/
.table-overview {
    margin-top: 30px;

    .t-headings {
        padding: 0 20px 30px 20px;

        .t-heading {
            color: color(text);
            font-weight: 800;
        }
    }

    .t-row {
        display: block;
        margin-bottom: 10px;
        padding: 0 15px;
        position: relative;
        transition: all 0.3s;
        text-decoration: none;

        &.chapter {
            padding: 0 15px 0 70px; // 15 + 55;

            &::before {
                background: color(background-light);
                content: '';
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                width: 70px;
            }

            .progress {
                position: absolute;
                top: 50%;
                left: 20px;

                @include transform(translateY(-50%));
                z-index: 15;

                .progress-value {
                    background-color: #FFF;
                }
            }

            .t-title {
                font-weight: 800;
            }

            &.in-progress {
                .t-title {
                    color: color(text);
                }
            }

            &.completed {
                .t-title {
                    color: color(primary);
                }
            }
        }

        .row {

            &:last-of-type {
                margin-bottom: 0;
            }

            > div {
                background: color(background-light);
                align-items: center;
                display: flex;
                font-size: 14px;
                padding: 10px 20px;
                position: relative;

                @include respond-to(up-to-sm) {

                    &.xs-label::before {
                        content: attr(data-label);
                        color: color(text);
                        font-weight: 800;
                        margin-right: 15px;
                        min-width: 35%;
                    }
                }

                @include respond-to(from-md) {

                    &::before,
                    &::after {
                        background-color: #FFF;
                        content: '';
                        height: 100%;
                        position: absolute;
                        top: 0;
                        width: 1px;
                    }

                    &::before {
                        left: 0;
                    }

                    &::after {
                        right: 0;
                    }

                    &:first-of-type::before {
                        content: none;
                    }

                    &:last-of-type::after {
                        content: none;
                    }
                }

                &.t-title {
                    color: color(secondary);
                    display: flex;
                    font-size: 20px;
                    min-height: 60px;
                    position: relative;
                    @include transition(color .3s ease);

                    span {
                        display: inline-block;
                        text-decoration: underline;
                    }

                    svg {
                        font-size: 16px;
                        margin-left: 10px;
                        @include transition(all .3s ease);
                    }
                }

                &.t-list {
                    span:not(:last-of-type)::after {
                        content: ',';
                        margin-right: 2.5px;
                    }
                }

                &.t-center {
                    justify-content: center;
                }

                @include respond-to(from-md) {
                    &.t-md-center {
                        justify-content: center;
                    }
                }
            }
        }
    }

    @include respond-to(from-lg) {
        a.t-row:hover {
            outline: 1px solid color(secondary);

            .row {

                > div {

                    &.t-title {

                        svg {
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
}

footer#bottom {
    background-image: url('../images/bg-footer.jpg');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    color: #FFF;
    padding: 15px 0;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    z-index: 300;

    @include respond-to(up-to-md){
        background-size: 1725px;
    }
    
    .footer-column {
        
        .footer-copyright {
            display: inline-block;
            padding: 15px 0;

            a {
                &:hover {
                    color: lightblue;
                }
            }
        }
        
        .footer-logo {
            display: inline-block;
            flex: 0;
            margin-left: 15px;
            vertical-align: middle;
            
            img {
                max-height: 40px;
                max-width: none;
            }
        }
    }
    
    
    
    
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        @include transform(rotate(0deg));
    }
    to {
        @include transform(rotate(360deg));
    }
}

@keyframes rotating {
    from {
        @include transform(rotate(0deg));
    }
    to {
        @include transform(rotate(360deg));
    }
}

.loading-container {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    
    .loading-inner {
        position: relative;
        width: 120px;
        
        span {
            -ms-flex-align: center;
            align-items: center;
            color: color(secondary);
            display: -ms-flexbox;
            display: flex;
            height: 100%;
            font-style: italic;
            font-weight: bold;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            -webkit-appearance: smooth;
        }
        
        img.loading {
            width: 100%;
            
            @include animation(rotating 1.5s linear infinite);
        }
    }
    
    

}

.flex-1 {
    flex: 1;
}

.gap-30 {
    gap: 30px;
}

.steps {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 30px;
    position: relative;
    width: 100%;
    overflow: hidden;

    @include respond-to(from-md) {
        //justify-content: center;
        width: 100%;
    }

    &--2 { max-width: 85px; }
    &--3 { max-width: calc(85px * 2); }
    &--4 { max-width: calc(85px * 3); }
    &--5 { max-width: calc(85px * 4); }

    .step {
        background: color(border-light);
        border-radius: 50%;
        height: 16px;
        position: relative;
        width: 16px;

            &::before {
                content: '';
                position: absolute;
                background: color(border-light);
                height: 2px;
                position: absolute;
                top: calc(50% - 1px);
                right: 0;
                width: 50vw;
                z-index: -1;
            }

        &--active {
            background: color(secondary);

            &::before {
                background: color(secondary);
            }
        }
        
        span {
            display: none;
        }
    }
}


.rsbtn .rsbtn_exp .rsbtn_exp_inner .rsbtn_player_item {
    width: auto !important;
}