form {
    background-color: color(background-light);
    padding: 15px;
	position: relative;
	-webkit-border-bottom-right-radius: 36px;
	-moz-border-radius-bottomright: 36px;
	border-bottom-right-radius: 36px;

	@include respond-to(from-md) {
		padding: 30px;
	}

	.close-form {
		position: absolute;
		top: 15px;
		right: 15px;
		bottom: auto;
		left: auto;
		cursor: pointer;

		* {
			pointer-events: none;
		}
	}

	&#multiple-questions {
		background-color: transparent;
		margin-top: 30px;
		padding: 0;

		.answer-block,
		.question-block {
			margin-bottom: 30px;

			.inner {
				background-color: color(background-light);
				padding: 15px;
                position: relative;
				-webkit-border-bottom-right-radius: 30px;
				-moz-border-radius-bottomright: 30px;
				border-bottom-right-radius: 30px;

				@include respond-to(from-md) {
					padding: 30px;
				}

                input[type="checkbox"] {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                }
			}

            p.autosave {
                text-align: right;
                font-size: 10px;
                font-style: italic;
            }
		}
	}
    
    :first-child {
        margin-top: 0;
    }

	&#fillintheblanks {
		background: none;
		font-size: 20px;
		line-height: 37px;
		padding: 0;

		.fillintheblanks-answer {
			background-color: color(background-light);
			display: inline-block;
			line-height: 20px;
			padding: 5px 35px 5px 10px;
			position: relative;

			&.answer-correct,
			&.answer-incorrect {

				&::after {
					content: '';
					display: block;
					height: 22px;
					position: absolute;
					top: 50%;
					right: 10px;
					width: 22px;
					@include transform(translateY(-50%));
				}
			}

			&.answer-correct {
				color: color(correct);

				&::after {
					background-image: url('../images/radio-true.svg') !important;
				}
			}

			&.answer-incorrect {
				color: color(warning);

				&::after {
					background-image: url('../images/radio-false.svg') !important;
				}
			}
		}

		.custom-select {

			.select-selected {
				line-height: 20px;

				&::after {
					top: 13px;
				}

				&.select-arrow-active {
					top: 7px;
				}
			}

			.select-items {

				div {
					font-size: 20px;
				}
			}
		}
	}
    
    label {
        display: block;
        font-family: $font-stack;
		font-weight: normal;
		margin-bottom: 10px;
	}

	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="password"],
	textarea,
	select {
		background: color(input) none;
		border: 0;
		border-radius: 0;
		color: color(text);
		display: block;
		font-family: $font-stack;
		font-size: 16px;
		font-weight: 400;
		font-style: italic;
		height: 38px;
		padding: 5px 15px;
		outline: none;
		vertical-align: middle;
		width: 100%;
	}

	textarea {
		height: 125px;
		max-width: 100%;
		min-height: 125px;
		padding: 10px 15px;
		font-style: normal;
	}

	input[type="submit"] {
		background: color(primary) none;
		border: 0;
		border-radius: 0;
		color: #FFF;
		cursor: pointer;
		display: inline-block;
		font-size: 16px;
		font-weight: 500;
		min-width: 230px;
		margin: 0;
		margin-bottom: 0;
		padding: 10px;
		text-align: center;
		vertical-align: middle;
		white-space: nowrap;

		&:hover {
			background: color(secondary) none;
		}

		&:disabled {
			background: color(border-dark) none;
		}
	}

	button[type="submit"]{
		text-transform: initial;
		font-weight: 100;
	}

	.form-field {
    	margin-top: 20px;
    	
    	&:first-of-type {
        	margin: 0;
    	}
    	
    	.field-error {
        	color: color(warning);
            font-size: 13px;	
            margin-top: 5px;
    	}

		.validation-answer {
			h2 {
				font-size: 18px;
				margin-top: 0;
			}
		}

		ul.checkbox,
    	ul.radio {
        	margin: 0;
        	padding: 0;

        	li {
        	    list-style-type: none;
        	    margin: 0 0 5px 0;

				input[type="checkbox"],
				input[type="radio"]{
                    visibility: hidden;
                    position: absolute;
                    left: -9999px;
        	    }

        	    label {
            	    background-image: url('../images/radio.svg');
            	    background-position: left 9px;
            	    background-repeat: no-repeat;
            	    color: color(text);
            	    cursor: pointer;
            	    display: -ms-flexbox;
                    display: flex;
            	    font-size: 16px;
            	    line-height: 20px;
            	    margin: 0;
            	    padding-left: 30px;
            	    width: 100%;

            	    span {
                	    display: block;
                	    flex-grow: 1;
                	    padding: 10px;

                	    &.validation-message {
                    	    background-color: color(background-light) !important;
                    	    color: color(text);
                    	    padding: 10px 10px 10px 30px;
                    	    flex-grow: 0;
							font-size: 15px;
							font-style: italic;
							font-weight: 100;
                    	    min-width: 145px;

							> div {
								color:color(text);
							}
                	    }
            	    }
        	    }

        	    input[type="radio"]:checked + label {
            	    background-image: url('../images/radio-checked.svg');
        	    }

        	    &.correct {



            	    label {
                	    background-image: url('../images/radio-true.svg') !important;

                	    span {
                    	    background-color: color(correct);
                    	    color: #fff;
                    	    font-weight: 100;
                    	    position: relative;

                    	    &.validation-message {
                        	    &::after {
                            	    content: none;
                        	    }
                    	    }
                	    }
            	    }
        	    }

        	    &.incorrect {

            	    label {
                	    background-image: url('../images/radio-false.svg') !important;

						span {
							background-color: color(warning);
							color: #FFF;
							position: relative;

							&.validation-message {
								color: color(warning);

								&::after {
									content: none;
								}
							}
						}
            	    }

            	    &.chosen-answer {

                	    span {
                    	    background-color: color(warning);
                    	    color: #FFF;
                    	    position: relative;

                    	    &.validation-message {
                        	    color: color(warning);

                        	    &::after {
                            	    content: none;
                        	    }
                    	    }
                	    }
            	    }
        	    }


        	}
    	}

		ul.checkbox {
			li {
				label {
					background-image: url('../images/checkbox.svg');
					background-size: 22px 22px;
				}

				input[type="checkbox"]:checked + label {
					background-image: url('../images/checkbox-checked.svg');
				}

				&.correct {

					label {
						background-image: url('../images/checkbox-true.svg') !important;
					}
				}

				&.incorrect {

					label {
						background-image: url('../images/checkbox-true.svg') !important;
					}
				}
			}
		}
    	
    	.true-false {
        	background-color: #FFF;
        	border: 1px solid color(border-light);
            display: -ms-flexbox;
            display: flex;
        	margin: 0;
        	max-width: 300px;
        	padding: 0;
        	position: relative;
        	
        	input[type="radio"]{
                visibility: hidden;
                position: absolute;
                left: -9999px;
    	    }
        	
        	label {
            	color: #8e8e8e;
            	display: inline-block;
            	flex: 1;
            	list-style-type: none;
            	margin: 0;
            	padding: 10px;
            	position: relative;
            	text-align: center;
            	z-index: 10;
				cursor: pointer;
        	}
        	
    	    .switcher {
        	    background-color: color(secondary);
        	    display: none;
        	    height: 100%;
        	    position: absolute;
        	    top: 0;
        	    left: 0;
        	    width: 50%;
        	    will-change: transform;
        	    
        	    @include transition( transform 0.3s ease );
    	    }
    	    
    	    input[type="radio"]:checked + label {
        	    color: #FFF;
        	    font-weight: bold;
    	    }
    	    
    	    input[type="radio"]:checked ~ .switcher {
        	    display: block;
    	    }
    	    
    	    input[type="radio"][value="true"]:checked ~ .switcher {
        	    left: 0;
    	    }
    	    
    	    input[type="radio"][value="false"]:checked ~ .switcher {
        	    @include transform( translateX(100%) );
    	    }

    	}
	}
	
	.form-errors {
    	color: color(warning);
	}
	
	.form-submit {
    	margin-top: 50px;
    	text-align: right;

		.error-messages {
			margin-top: 15px;
		}
	}
	
	&.floating-labels {

		h2 {
			color: #5e65ac;
			text-transform: uppercase;
		}
    	
    	.form-field:not(.no-floating-label) {
        	position: relative;
        	
        	label.field-label {
            	background-color: #FFF;
            	color: color(text);
        		display: block;
        		font-size: 16px;
        		font-weight: 300;
        		font-style: italic;
            	margin: 0;
            	padding: 0 5px;
            	position: absolute;
            	top: 10.5px;
            	left: 10px;
            	
            	@include transition(all 0.3s ease);
        	}
        	
        	&.float-label {
            	
            	label.field-label {
                	color: color(secondary);
                	font-size: 12px;
                	top: -10px;
            	}
        	}
    	}
    	
	}
}

/* The container must be positioned relative: */
.custom-select {
	display: inline-block;
	position: relative;
	text-align: left;

	select {
		display: none; /*hide original SELECT element: */
	}

	@media all and (-ms-high-contrast: none){
		vertical-align: middle;

		select {
			border: 1px solid color(border-light);
			display: block;
			padding: 0;
		}
	}

	.readspeaker-options {
		display: none;
	}

	.select-selected {
		background-color: color(background-light);
		padding: 5px 10px;

		/* Style the arrow inside the select element: */
		&::before {
			background-color: #FFF;
			border-radius: 50%;
			content: '';
			height: 22px;
			position: absolute;
			top: 50%;
			right: 10px;
			width: 22px;

			@include transform(translateY(-50%));
		}

		&::after {
			position: absolute;
			content: "";
			top: 13px;
			right: 16px;
			width: 0;
			height: 0;
			border: 5px solid transparent;
			border-color: color(text) transparent transparent transparent;
		}

		/* Point the arrow upwards when the select box is open (active): */
		&.select-arrow-active::after {
			border-color: transparent transparent color(text) transparent;
			top: 7px !important;
		}
	}

	/* Style items (options): */
	.select-items {
		background-color: color(background-light);
		min-width: 100%;
		padding: 0 10px 10px 10px;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 99;

		div {
			background-color: #FFF;
			font-size: 16px;
			line-height: normal;
			padding: 5px 10px;
			margin-bottom: 5px;
			white-space: nowrap;

			&:last-of-type {
				margin-bottom: 0;
			}

			@include respond-to(from-lg) {
				&:hover {
					background-color: color(secondary);
					color: #FFF;
				}
			}

			&.same-as-selected {
				background-color: color(background-light);
				color: color(text);
			}
		}
	}

	/* style the items (options), including the selected item: */
	.select-items div,
	.select-selected {
		color: color(text);
		cursor: pointer;
	}

	.select-items,
	.select-selected {
		padding-right: 30px;
	}

	/* Hide the items when the select box is closed: */
	.select-hide {
		display: none;
	}
}

#search-page {
    input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="password"],
	textarea,
	select {
				
		&#s {
    		background: #FFF none;
    		border: 1px solid color(border-light);
		}
	}
}

#coach_form,
#group_form {
	input[type="submit"] {
		min-width: 0;
		width: 100%;
	}
}

form#filters {
	margin-bottom: 30px;

	.toggle-filters {
		border-bottom: 1px solid #FFF;
		color: color(text);
		padding-bottom: 15px;
		display: flex;

		span {
			font-size: 18px;
			font-weight: 700;
			flex: 1;
		}

	}

	h3, h4 {
		color: color(text);
		display: flex;

		span {
			flex: 1;
			display: inline-block;
		}

		i, svg {
			vertical-align: middle;
		}
	}

	h4 {
		font-size: 18px;
        font-weight: normal;
		margin-bottom: 10px;

		i, svg {
			font-size: 16px;
		}
	}

	.form-field {

		&.search-field {
			background-color: #FFF;
			margin-bottom: 30px;
			padding-right: 30px;
			position: relative;

			i, svg {
				color: color(background-light);
				cursor: pointer;
				position: absolute;
				top: 50%;
				right: 15px;

				@include transform(translateY(-50%));
			}
		}

		&.group-organisation {
			border-bottom: 1px solid #FFF;
			margin: 0;
			padding: 15px 0;

			&:first-of-type {
				margin-bottom: 15px;
			}
		}
	}


}