// Colors
$colors: (
    primary:                #81b24a,
    secondary:              #5e65ac,
    tertiary:               #b2d493,
    text:                   #1d1d1b,
    link:                   #5e65ac,
    link-hover:             #7dad4f,  //old
    input:                  #FFF,     //old
    background-light-primary: #dfefd9,
    background-light:       #e7e8f3,
    border-light:           #bdbbb8,  //old
    border-dark:            #000,
    //footer:                 #272525,
    //progress:               #ff8f00,
    no-progress:            #bdbbb8,
    in-progress:            #ff8f00,
    progress:               #5e65ac,
    complete:               #81b24a,
    correct:                #81b24a,
    warning:                #e83c4e,
    overlay:                rgba(0,0,0,0.9),
    overlay_correct:        rgba(125,173,79,0.9),
    overlay_warning:        rgba(208,74,87,0.9),
    overlay_secondary:      rgba(78,143,198,0.9),
    overlay_correct_light:  rgba(125,173,79,0.15),
    overlay_warning_light:  rgba(208,74,87,0.15),
    overlay_secondary_light:rgba(78,143,198,0.15),
);

// Font settings
$font-stack: 'roboto', sans-serif;


// Bootstrap breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Animation settings
$duration: .3s;