h1 {
    color: color(secondary);
    
    &.page-main-title {
        background-color: rgba(125,173,79,0.65);
        color: #FFF;
        padding: 15px;
        pointer-events: none;
        position: absolute;
        top: 20px;
        left: 0;
        text-align: center;
        width: 100%;
        z-index: 20;
        
        &.secondary {
            background-color: rgba(78,143,198,0.65);
        }
    }
}

h2 {
    color: color(text);

    &.no-margin {
        margin: 0;
    }

    &.subtitle {
        color: color(secondary);
        font-size: 1em;
        margin-top: -20px;
    }

    &.section-title {
	    font-size: 2em;
	    color: color(secondary);
	    text-transform: uppercase;
	    margin-top: 0;
    }
    
    &.section-sub-title {
	    font-size: 1.5em;
	    color: color(primary);
	    margin: 0;
	    text-decoration: none;
	    
	    a {
	    	color: color(primary);
	    	
	    	&:hover {
		    	color: color(secondary);
	    	}	    
	    }
    }
    
    &.has-arrow {
	    
	    svg {
		    
			font-size: 16px;
			margin-left: 10px;
			-webkit-transition: margin-left 0.3s ease;
			transition: margin-left 0.3s ease;		    
	    }
	    
	    
	    a {
		    
		    &:hover {
			    
			    svg {
				    
				    margin-left: 20px;
			    }
		    }		    
	    }
    }
    
    a {
        color: color(text);
        text-decoration: none;
        
        &:hover {
            color: color(link-hover);
        }
    }
}

h3 {

    &.question-title {
        color: color(secondary);
        font-size: 16px;
    }
}

h4 { }

h5 { }

h6 { }

a,
span.link,
button.link {
    color: color(link);
    cursor: pointer;
    text-decoration: underline;
    
    @include transition(color $duration ease, background-color $duration ease);
    
    &:hover,
    &:focus {
        color: color(link-hover);
    }
    
    &.has-arrow {
	    
	    svg {
            position: relative;
			font-size: 16px;
			margin-left: 10px;
			-webkit-transition: margin-left 0.3s ease;
			transition: all 0.3s ease;
            left: 0;
	    }
	    
	    &:not(.arrow-left):hover {
		    
		    svg {
			    left: 10px;
		    }
	    }

        &.arrow-left {

            svg {
                margin-right: 10px;
            }

            &:hover {
                svg {
                    left: -10px;
                }
            }

        }
    }
}

button.link {
    background: none;
    border: 0;
}

a.button,
button:not(.link) {
    background: color(primary) none;
    border: 0;
    border-radius: 0;
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    font-family: $font-stack;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    width: 100%;
    margin-bottom: 0;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    text-transform: initial;
    vertical-align: middle;
    white-space: nowrap;
    outline: none;


    &:not(:disabled):hover {
        background: color(secondary) none;
    }

    &.margin-top-auto {
        margin-top: auto;
    }

    &.margin-bottom-auto {
        margin-bottom: auto;
    }

    &.primary {
        background: color(primary) none;

        &:hover {
            background: color(secondary) none;
        }
    }

    &.secondary {
        background: color(secondary) none;

        &:hover {
            background: color(primary) none;
        }
    }

    &.light {
        background: color(background-light) none;
        color: color(text);

        &:hover {
            background: color(secondary) none;
            color: #FFF;
        }
    }

    svg {
        margin: -2px 10px 0 10px;
        vertical-align: middle;

        &.fa-spinner {
            @include animation(spin 2s linear infinite);
        }
    }

    &.add {
        svg {
            margin: -2px 0 0 10px;
        }
    }


    &.delete {
        background: color(warning);

        &:not(:disabled):hover {
            background: color(secondary) none;
        }

        svg {
            margin: -2px 10px 0 0;
        }
    }

    &:disabled {
        background: color(overlay_secondary_light);
        color: color(text);
        cursor: not-allowed;

        &.download {
            background: color(overlay_correct_light);
        }
    }


    @include respond-to(from-md) {
        max-width: 230px;

        &.auto-width {
            max-width: none;
            width: auto;
        }
    }

    @include respond-to(up-to-sm){
        & + a.button,
        & + button {
            margin-top: 15px;
        }
    }

}


footer a {
    color: #FFF;
    font-weight: 400;
}

@include respond-to(from-md){
    .button-container a.button + a.button {
        margin-top: 15px;
    }
}

p {
    line-height: 20px;

    &.error {
        color: color(warning);
        font-style: italic;
    }
    
    &.message {
        background-color: color(secondary);
        color: #FFF;
        display: block;
        font-style: italic;
        margin-bottom: 15px;
        padding: 10px 10px;
        width: 100%;
    
    }
    
    &.warning {
        background-color: color(warning);
        color: #FFF;
        display: block;
        font-style: italic;
        padding: 10px 10px;
        width: 100%;
    }
    
    &.form-message {
        margin: 0px 0px 15px 0px;
        
        @include respond-to(from-md){
            margin-bottom: 0;
        }
    }
    
    &.small {
	    font-size: 14px;
	    margin-top: 10px;
    }

    &.variants {
        font-size: 14px;
        font-style: italic;
        margin-top: -10px;
    }
}


.page-title-pagination {
    align-items: center;
    display: flex;
    gap: 30px;

    h1, a {
        display: inline-block;

        &:last-child {
            text-align: right;
        }
    }

    h1 {
        flex: 1;
    }
}

.form-select-all {
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 15px;
    text-decoration: underline;

    @include transition(color .3s ease);

    &.no-margin {
        margin: 0;
    }

    &:hover {
        color: color(primary);
    }
}

@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}