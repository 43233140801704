/*
 * Required helpers
 */
 
@import 'helpers/variables';
@import 'helpers/mixins';
@import 'helpers/functions';

/*
 * Styles
 */
 
#cookie-notice {
    background-color: color(secondary);
    color: #FFF;
    padding: 30px;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 400; 
    
    a {
        color: #FFF;
        text-decoration: underline;
        
        &:hover {
            text-decoration: none;
        }
    }
    
    button {
        background-color: #FFF;
        border: 1px solid color(secondary);
        color: color(secondary);
        min-width: 0;
        margin-left: 10px;
        padding: 10px 15px;
        
        @include respond-to(from-md){
            &:hover {
                background-color: color(secondary);
                color: #FFF;
                border: 1px solid #FFF;
            }
        }
    }
}